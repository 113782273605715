html,
body,
#root {
	margin: 0px;
	padding: 0px;
	height: 100%;
	border: none;
}

body {
	font-family: 'PingFang TC';
	font-style: normal;
	margin: 0;
}
